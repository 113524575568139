<template>
  <title-wrapper title="Zaloguj się">
    <login-form></login-form>
  </title-wrapper>
</template>
<script>
import LoginForm from '../components/LoginForm.vue';
import TitleWrapper from '../components/TitleWrapper.vue';

export default {
  name: 'Login',
  components: { LoginForm, TitleWrapper },
};
</script>
<style lang="scss" scoped></style>
